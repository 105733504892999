<template>
    <b-container class="al-legend__container">
        <div class="al-legend al-card">
            <div class="al-legend__body">
                <div class="al-legend__image">
                    <img src="@/assets/images/mascote-agent-a.png" alt="" />
                    <div class="al-legend__agent-info">
                        <h3 class="al-legend__agent-title">Агент А</h3>
                        <span class="al-legend__agent-subtitle">директор Секретной Службы</span>
                    </div>
                </div>
                <div class="al-legend__info">
                    <div class="al-legend__description">
                        <h3 class="al-legend__title">Легенда</h3>
                        <div class="al-legend__text" v-if="legend?.text">{{ legend.text }}</div>
                        <div class="al-legend__text" v-else>
                            Возможно, раньше вы не слышали о Секретной Службе, но мы, цифровые агенты, всегда держали
                            руку на пульсе современных технологий. Благодаря нашей работе Альфа-Банк оказался на острие
                            прогресса. Вы спросите, чем мы занимаемся? Я отвечу — добываем и анализируем секретные
                            данные, находим уникальные решения для бизнеса и внедряем их в рабочие процессы.<br /><br />

                            Но с недавних пор наша работа под угрозой. Корпорация «Омега» популяризирует «теорию
                            регресса», следуя которой, цифровые технологии отбирают работу у людей. Наши аналитики
                            прогнозируют, что в ближайшее время эта теория начнёт распространяться даже среди
                            сотрудников нашего Банка.<br /><br />

                            Как директор Секретной Службы, я принял решение укрепить штат наших агентов, чтобы
                            противостоять корпорации «Омега». Рад приветствовать вас в наших рядах!
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>
<script>
import AlBtn from '@/components/AlBtn';
import LegendApi from '@/services/legend';
export default {
    name: 'Legend',
    components: { AlBtn },
    data: () => ({
        legend: {},
    }),
    async created() {
        this.legend = await LegendApi.getLegend();
    },
};
</script>
